import Compressor from 'compressorjs';
import { isNil } from '@/shared/utils';
import heic2any from 'heic2any';

const pathImg = process.env.VUE_APP_PATH_IMG_URL;
const imagethumb = process.env.VUE_APP_PATH_IMG_THUMB_URL;

/**
 * @param {!any} elem
 * @return {Boolean}
 */
export const isBlob = (elem) => {
  if (isNil) return false;
  return elem instanceof Blob;
};

/**
 * @param {String} xid
 * @return  {Object}
 */
export const xidToImgObj = (xid) => {
	const fullPath = pathImg + xid;
	const thumbPath = imagethumb + xid;
	return { xid, src: fullPath, thumb: thumbPath };
};

export const b64toFile = (b64Data, filename, contentType) => {
	const sliceSize = 512;
	const byteCharacters = atob(b64Data);
	const byteArrays = [];
	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);
		const byteNumbers = new Array(slice.length);

		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}
	const file = new File(byteArrays, filename, {
		type: contentType
	});
	return file;
};

/**
 * @param {String} base64
 * @return {String}
 */
export const getExtensionFromBase64 = (base64) => {
	const parts = base64.split(';');
	const format = parts[0].split('/')[1];
	return format;
};

/**
 * @param {String} base64
 * @return {Blob}
 */
export const base64ToBlob = (base64) => {
	const parts = base64.split(';base64,');
	const contentType = parts[0].split(':')[1];
	const raw = window.atob(parts[1]);
	const rawLength = raw.length;
	const uInt8Array = new Uint8Array(rawLength);

	for (let i = 0; i < rawLength; ++i) {
		uInt8Array[i] = raw.charCodeAt(i);
	}

	return new Blob([uInt8Array], { type: contentType });
};

/**
 * set compression on base64 image type
 *
 * @doc https://github.com/fengyuanchen/compressorjs
 * @param {File|Blob} fileImg
 * @return {Base64}
 */
export const getCompressedImage = (fileImg) => {
  return new Promise((resolve, reject) => {
    const _f = isBlob(fileImg) ? fileImg : base64ToBlob(fileImg);
    new Compressor(_f, {
      quality: 0.8,
      mimeTypes: ['image/png', 'image/jpeg'],
      maxWidth: 2000,
      maxHeight: 2000,
      convertSize: 1000000,
      success(result) {
        resolve(result);
      },
      error(err) {
        reject(err);
      }
    });
  });
};

/**
 * Compress image for PWA application, limiting output size to 2000px max width/height.
 * this function can handle fileUri or base64 string
 * always return a blob file
 *
 * @param {String|URI|Base64|File} imageSource - The source image URL.
 * @param {String} [type='jpeg'] - [jpeg|png] - define output file mimeType
 * @param {Number} [maxSize=2000] - limit dimensions to 2000px max
 * @return {Promise<Blob>} - Image as a Blob in JPEG format.
 */
export const reScalingImgWithCanvas = async (imageSource, maxSize = 2000, type = 'jpeg') => {
  const outputMimeType = !['jpeg', 'png'].includes(type) ? 'jpeg' : type;

  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = imageSource instanceof File
      ? URL.createObjectURL(imageSource)
      : imageSource;

    img.onload = () => {
      URL.revokeObjectURL(img.src); // Clean up the temporary URL if it was used

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      // Calculate the scale to limit dimensions to `${maxSize}px`` max
      const scale = Math.min(maxSize / img.width, maxSize / img.height, 1); // Ensure scale is not more than 1

      // Set canvas size based on the calculated scale
      canvas.width = img.width * scale;
      canvas.height = img.height * scale;

      // Draw the image onto the canvas with resizing
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

      // Convert the canvas content to a Blob
      canvas.toBlob((blob) => resolve(blob), `image/${outputMimeType}`);
    };

    img.onerror = (e) => {
      if (imageSource instanceof File) URL.revokeObjectURL(img.src);
      reject(e);
    }
  });
};

// /**
//  * @param {String} base64String
//  * @return {Boolean}
//  */
// export const isBase64ImgFile = (base64String) => {
// 	if (isNil(base64String)) return false;
// 	const img = new Image();
// 	img.src = base64String;
// 	return img.complete && img.naturalWidth !== 0;
// };

/**
 * Check if the provided object is an image file based on its MIME type.
 *
 * @doc https://developer.mozilla.org/fr/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
 * @param {File} file - The file object to check.
 * @return {Boolean}
 */
export const isImageFile = (file) => {
  /** @type {Array<String>} supportedImageTypes */
  const supportedImageTypes = ['image/png', 'image/jpeg', 'image/webp'];
  return file && supportedImageTypes.includes(file.type);
};

/**
 * Verify if the file is really a good HEIC type format file by trying to convert it to other format through a heic2any library.
 * if the conversion pass, that signify that the file has HEIC good format.
 * 
 * @param {File} file - The file object to check. 
 * @return {Boolean}
 */
export const isFileRealTypeHeic = async (file) => {
			try {
				// check if we can convert heic file to other type.
				const blobResult = await heic2any({ blob: file, toType: 'image/png' });
				if (blobResult) return true;
				return false;
			} catch (error) {
				// if not, error should say that file mime type is already browser readable.
				return false;
			}
}

/**
 * Check if the file has mime type 'image/heic' or it ends with '.heic'
 * It is kind of preliminary check before detecting the real type of the file.
 * 
 * @param {File} file - The file object to check.
 * @return {Boolean}
 */
export const detectHEIC = (file) => {
			// Check if the MIME type starts with 'image/heic' or 'image/heif'
			let mimeType = file.type.startsWith('image/heic') || file.type.startsWith('image/heif');

			// If MIME type is not available or does not match, check the file extension
			if (!mimeType) {
				const fileExtension = file.name.split('.').pop().toLowerCase();
				mimeType = fileExtension === 'heic' || fileExtension === 'heif';
			}

			return mimeType;
}

/**
 * Method to dispatch File data to upload according to it's real type.
 * 
 * @param {File} file - The file object to check.
 * @return {Object} - The file data result.
 */
export const provideFileDataToUpload = async (file) => {
    if (isImageFile(file)) {
      const scalingResult = await reScalingImgWithCanvas(file);
      return {
        file: scalingResult,
        name: file.name,
        type: file.type
      };
    } else if (detectHEIC(file)) {
      const isReal = await isFileRealTypeHeic(file);
      if (isReal) {
        return {
          file: file,
          name: file.name,
          type: 'image/heic'
        };
      }
      const heicScaling = await reScalingImgWithCanvas(file);
      return {
        file: heicScaling,
        name: file.name.split('.')[0] + '.jpg',
        type: 'image/jpeg'
      };
    } else
      return {
        file: file,
        name: file.name,
        type: file.type
      };
}

